:root {
  --primary-color: #c52422;
  --secondary-color: #ffffff;
  --text-color: #333333;
  --border-radius: 8px;
}

@font-face {
  font-family: 'Copperplate Gothic';
  src: url('/src/assets/fonts/Copperplate_Bold/CopperplateCC-Bold.ttf') format('ttf'),
       url('/src/assets/fonts/Copperplate_Bold/CopperplateCC-Bold.woff') format('woff'),
       url('/src/assets/fonts/Copperplate_Bold/CopperplateCC-Bold.woff2') format('woff2');
  

  font-style: normal;
  font-display: swap;
}

body {
  font-family: sans-serif;
  background-color: var(--secondary-color);
  color: var(--text-color);
  font-size: 1rem;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

body a{
  color: var(--primary-color);
}

header, footer{
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
}

body::-webkit-scrollbar{
  width: 0.5rem;
}

body::-webkit-scrollbar-track{
  background-color: #555;
}

body::-webkit-scrollbar-thumb{
  background-color: var(--primary-color);
  border-radius: 2rem;
}

/* Header styles */
header {
  background-color: white;
  padding: 0.4rem 0;
  border-bottom: 2px solid #f5f5f5;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo-container {
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.logo-container img {
  height: 45px;
  transition: all 0.5s ease;
}

.logo-container .logo-text {
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: bold;
  color: black;
  opacity: 0;
  transform: translateY(20px);  /* Start below */
  transition: transform 0.5s ease, opacity 0.5s ease;
  margin-left: 10px;
  cursor:default;
}

/* Hover effects */
.logo-container:hover img {
  transform: translateX(12px);
  cursor:pointer  /* Slide logo to the left */
}

.logo-container:hover .logo-text {
  opacity: 1;
  transform: translateY(0);  /* Slide text up from bottom */
}

/* Navigation Links */
nav ul {
  display: flex;
  list-style: none;
}

nav ul li {
  margin-left: 2rem;
}

nav ul li a {
  color: black;
  text-decoration: none;
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  transition: color 0.3s ease;
}

nav ul li a:hover {
  color: #c52422;
}



/* Button styles */
.btn {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
}

.btn:hover {
  background-color: #a41e1c;
}

/* Home page styles */
.hero {
  background-image: linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5)),url('../images/home3.png');  /* Add your image in the public/images folder */
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 6rem 0;
}


/*.hero::before{
  background-color: rgba(0,0,0,0.5);
  content: '';
  position: absolute;
  width: 100%;
  height: 84.5%;
  top: 0;
  left: 0;
  z-index: 1;
}*/


.hero h1 {
  font-size: 3rem;
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  margin-bottom: 1.5rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

section.about {
  background-color: #f5f5f5;
  padding: 4rem 0;
  margin: 0.4rem;
}

.about h2 {
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  color: #333;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
}

.about p {
  font-size: 1.2rem;
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;
}

section.culture {
  padding: 4rem 0;
  text-align: center;
}

.culture h2 {
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  color: #c52422;
  font-size: 2.5rem;
  margin-bottom: 2rem;
}

.culture .benefits {
  display: flex;
  justify-content: space-around;
  margin-top: 2rem;
}

.culture .benefit-item {
  width: 30%;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.culture .benefit-item:hover {
  transform: translateY(-5px);
}

.culture .benefit-item h3 {
  color: #c52422;
  margin-bottom: 1rem;
}

.culture .benefit-item p {
  font-size: 1rem;
  color: #555;
}


/* Job list styles */
.job-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem 0;
}

.job-item {
  background-color: var(--secondary-color);
  border: 1px solid #ddd;
  border-radius: var(--border-radius);
  padding: 1.5rem;
  transition: transform 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.job-item:hover {
  transform: translateY(-5px);
}

.job-item h3 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.job-item p {
  margin-bottom: 1rem;
}

/* Footer styles */
footer {
  background-color: var(white);
  color: var(--primary-color);
  text-align: center;
  padding: 1rem 0;
  display: contents;
}

footer p{
  font-size: 0.8rem;
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content-wrap {
  flex: 1; /* This makes sure the content area grows and pushes the footer to the bottom */
}

/* Slideshow Container */
.slideshow-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 400px;
  position: relative;
}

.slideshow-image {
  width: 50%;
  height: 100%;
}

.slideshow-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slideshow-content {
  width: 50%;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slideshow-content h2 {
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  font-size: 2rem;
  color: #c52422;
}

.slideshow-content p {
  font-size: 1.1rem;
  color: #333;
}

.slideshow-controls {
  position: absolute;
  bottom: -3rem;
  left: 10px;
}

.slideshow-controls button {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-right: 10px;
  cursor: pointer;
}

.slideshow-arrows {
  position: absolute;
  bottom: -3rem;
  right: 10px;
}

.slideshow-arrows button {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  margin-left: 10px;
  cursor: pointer;
}

.slideshow-arrows button:hover,
.slideshow-controls button:hover {
  background-color: #c52422;
}
/* Hero section */


.hero h1 {
  font-size: 2.5rem;
}

.hero p {
  font-size: 1.2rem;
}

/* Navbar styles */


.nav-links {
  display: flex;
  list-style: none;
  transition: all 0.5s ease;
}

.nav-links li {
  margin-left: 2rem;
}

.nav-links li a {
  color: black;
  text-decoration: none;
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  padding:0.3rem
}

.nav-links li button{
  color: #c52422;
  text-decoration: none;
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  border: none;
  background-color: white;
  border-radius: 0.1rem;
}

.nav-links li button:hover{
  cursor: pointer;
  transition: 0.5s ease;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger div {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 4px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  /* Stack Work Culture boxes vertically */
  .benefits {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;
  }
  .culture .benefit-item{
    width: 80%;
  }
  /* Hide nav links and show hamburger menu */
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: white;
    width: 100%;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease, opacity 0.5s ease;
  }

  .nav-links li {
    margin: 1.5rem 0;
  }

  .hamburger {
    display: flex;
  }

  .nav-links.active {
    display: flex;
    opacity: 1;
    max-height: 300px; /* Adjust this to accommodate the full height of the menu */
    animation: slideDown 0.5s ease forwards;
  }
  
  .slideshow-arrows {
    position: absolute;
    bottom: -3.5rem;
    right: 10px;
  }
  .slideshow-controls {
    position: absolute;
    bottom: -3.5rem;
    left: 10px;
  }
  @keyframes slideDown {
    from {
      max-height: 0;
      opacity: 0;
    }
    to {
      max-height: 300px; /* Adjust this value based on your content */
      opacity: 1;
    }
  }
}

/* Authentication Form Styles */
.auth-form {
  max-width: 600px;
  margin: 0.2rem auto;
  padding: 0.75rem;
  background-color: white;
  border: none;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.auth-form h2 {
  text-align: center;
  margin-bottom: 1.5rem;
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
  color: #c52422;
}

.auth-form input {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.search-bar {
  width: 40%;
  padding: 10px;
  margin-bottom: 20px;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.actions{
  padding: 1rem;
}

.actions svg{
  margin-right: 1rem;
}

.auth-form input:hover{
  border: 1px solid #c52422;
}

.auth-form .btn {
  width: 80%;
  position: relative;
  left: 8%;
  padding: 0.8rem;
  background-color: #c52422;
  color: white;
  border: none;
  border-radius: 4px;
  font-weight: bold;
}

.auth-form .btn:hover {
  background-color: #a41e1c;
}

.auth-form .error {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 1rem;
}

.auth-form p {
  position: relative;
  left: -1.5%;
  text-align: center;
  margin-top: 1rem;
  font-size: 0.7rem;
}
.auth-form a{
  color: #a41e1c;
  text-decoration: none;
}

.auth-form .logo-img img{
  height: 10rem;
}

.success-message{
  color: green;
}

.admin-dashboard {
  text-align: center;
  margin-top: 50px;
}

.admin-data{
  margin-top: 5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.dashboard-options {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.dashboard-options a {
  background-color: #c52422;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  border-radius: 5px;
}

.dashboard-options a:hover {
  background-color: #a41e1c;
}

.not-found{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.none-img img{
  height:15rem
}

.not-found-des a{
  color: #c52422;
}

select {
  background-color: white;
  color: #333;
  font-size: 16px;
  border-color: #555;
  width: 100% ;
  height: 5%;
}


.create-admin-container {
  display: flex;
  padding: 20px;
  gap: 20px;
}

.form-container {
  width: 25%;
}

.table-container {
  width: 75%;
}

.password-field {
  position: relative;
}

.password-field input {
  width: calc(100% - 40px);
  padding-right: 40px;
}

.toggle-password {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 1.2em;
  color: #c52422;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th, table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  text-align: center;
}

table th {
  background-color: #f4f4f4;
  color: #c52422;
  font-size: 1rem;
}

table td {
  font-size: 0.9rem
}

table .fa-key {
  font-size: 20px;
  cursor: pointer;
}

table .fa-key:hover {
  color: #a41b1a;
}

.success {
  color: green;
}

.error {
  color: red;
}

.create-admin-container .form-container input[type="text"],.create-admin-container .form-container input[type="email"],.create-admin-container .form-container input[type="password"] {
  width: 100%;
  padding: 12px;
  margin: 8px 0;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.view-users-container {
  padding: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

table th, table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

table th {
  background-color: #f4f4f4;
  color: #333;
}

table td {
  font-size: 16px;
}

table .fa-key, table .io-enter-outline {
  font-size: 20px;
  cursor: pointer;
}

table .fa-key:hover, table .io-enter-outline:hover {
  color: #a41b1a;
}

.error {
  color: red;
}

.user-dashboard {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-image {
  position: relative;
  cursor: pointer;
}

.profile-image img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.camera-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
}

.profile-details {
  flex: 1;
  padding-left: 20px;
}

.profile-body {
  display: flex;
  margin-top: 20px;
}

.profile-left {
  width: 30%;
}

.profile-left .note{
  font-size: 0.6rem;
}

.profile-right {
  width: 70%;
}

.profile-right ul {
  list-style: none;
}

.profile-right li {
  padding: 10px;
  background-color: #f4f4f4;
  margin-bottom: 10px;
  border-radius: 5px;
}

.profile-left ul {
  list-style: none;
}

.profile-left li {
  margin-bottom: 10px;
}

.edit-profile button {
  padding: 10px 20px;
  background-color: #c52422;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.edit-profile button:hover {
  background-color: #a41e1c;
}


/* UserDashboard Styling */

.user-dashboard {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif;
}

.profile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding: 1.5rem;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-image {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.camera-icon {
  position: absolute;
  bottom: 5px;
  right: 5px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 50%;
  color: white;
}

.profile-details {
  flex: 1;
  padding-left: 2rem;
}

.profile-details h2 {
  font-size: 2rem;
  color: #333;
}

.profile-details p {
  color: #777;
  margin: 0.3rem 0;
}

.edit-profile button {
  background-color: transparent;
  color: #c52422;
  font-size: 0.9rem;
  font-weight: bold;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.edit-profile button:hover {
  color: #ff5b5b;
}

.profile-completeness {
  width: 100px;
  text-align: center;
}

.profile-completeness p {
  font-size: 0.8rem;
  color: #333;
  margin-top: 0.5rem;
}

.profile-body {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.profile-left {
  flex: 1;
  padding-right: 1rem;
}

.profile-left h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 0.5rem;
}

.profile-left .bio {
  color: #555;
  margin-bottom: 1rem;
  line-height: 1.6;
  font-size: 0.8rem;
}

.profile-left p{
  color: #555;
  margin-bottom: 1rem;
  line-height: 1.6;
}

.profile-left ul {
  list-style: none;
  padding: 0;
}

.profile-left ul li {
  display: flex;
  align-items: center;
  margin-bottom: 0.7rem;
}

.profile-left ul li a {
  margin-left: 0.5rem;
  color: #c52422;
  text-decoration: none;
}

.profile-left ul li a:hover {
  text-decoration: underline;
}

.profile-right {
  flex: 2;
}

.profile-right h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 1rem;
}

.profile-right ul {
  list-style: none;
  padding: 0;
}

.profile-right ul li{
  display: flex;
  justify-content: space-between;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-bottom: 1rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
.password-input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.password-input-wrapper input {
  width: 100%;
  padding-right: 40px; /* Ensure the password input doesn't overlap with the button */
}

.password-toggle-btn {
  position: absolute;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #c52422;
}

.password-toggle-btn:hover {
  background: none;
}


.password-field {
  position: relative;
  width: 100%;
}

.password-toggle-btn {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: #c52422; /* Primary color */
  padding: 0;
}

/* Switch styling */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--primary-color);
}

input:checked + .slider:before {
  transform: translateX(14px);
}

.password-toggle-btn:hover {
  color: #c52422;
  background: none;
}

input[type="password"], input[type="text"] {
  width: 70%;
  padding-right: 40px; /* Add padding to avoid overlap with the eye icon */
}

.profile-right .application-title {
  font-size: 1rem;
  font-weight: bold;
  color: #333;
}

.profile-right .application-title a{
  text-decoration: none;
  color: black;
}
.profile-right .application-status {
  font-size: 0.9rem;
  color: #c52422;
}


.user-dashboard button {
  background-color: #c52422;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  padding: 0.7rem 1.5rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #ff5b5b;
}

textarea {
  width: 100%;
  height: 100px;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.user-dashboard input[type='text'],.user-dashboard input[type='email'],.user-dashboard input[type='password'] {
  width: 100%;
  padding: 0.7rem;
  margin-bottom: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.profile-header input[type='text']:focus, input[type='email']:focus, input[type='password']:focus, textarea:focus {
  border-color: #c52422;
  outline: none;
}

.profile-edit-icon {
  font-size: 1.2rem;
  margin-right: 0.5rem;
}

.circular-progressbar {
  margin: auto;
  display: block;
  width: 90px;
  height: 90px;
}

@media (max-width: 768px) {
  .profile-body {
    flex-direction: column;
  }
  
  .profile-header {
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .profile-completeness {
    margin-top: 1rem;
  }
}
.user-dashboard {
  padding: 20px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  margin-bottom: 20px;
}

.profile-header input[type="text"]{
  width: 75%;
}

.profile-image {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.default-profile-icon {
  font-size: 100px;
  color: #ccc;
}

.camera-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 24px;
  background-color: black;
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
}

.profile-details h2 {
  font-size: 24px;
  margin: 0;
}

.edit-profile button {
  background-color: #c52422;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  left: -100%;
}

.profile-completeness {
  text-align: center;
  position: relative;
  left: -3%;
}

.profile-left ul {
  list-style-type: none;
  padding: 0;
}

.profile-left ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.profile-left ul li input {
  flex-grow: 1;
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.add-icon, .delete-icon {
  cursor: pointer;
  margin-left: 10px;
}

.add-icon {
  color: #28a745;
}

.delete-icon {
  color: #c52422;
}

.application-title {
  font-weight: bold;
}

.application-status {
  font-style: italic;
}

.post-job-form {
  width: 60%;
  margin: 0 auto;
  font-family: sans-serif;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

.form-group label .required {
  color: red;
  margin-left: 5px;
}
.responsibilities .skills .qualifications .questions{
  width: 60%;
}

.form-group input,
.form-group textarea,
.form-group select {

  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.salary-inputs input {
  display: inline-block;
  width: calc(33% - 10px);
  margin-right: 10px;
}

.post-job-form button {
  padding: 10px 20px;
  background-color: #c52422;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.post-job-form button:hover {
  background-color: #a81d1d;
}

.form-group ul {
  list-style-type: none;
  padding: 0;
}

.form-group ul li {
  margin-bottom: 10px;
}

.form-group ul li button {
  margin-left: 10px;
  background-color: #ff6347;
}

.form-group ul li button:hover {
  background-color: #ff4500;
}

.salary-inputs {
  display: flex;
  gap: 10px;
}

.jobs-page {
  padding: 20px;
}

.search-filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-bar {
  width: 70%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.filter-btn {
  padding: 10px 20px;
  background-color: #c52422;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.filter-popup {
  margin-top: 10px;
  background: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
}

.job-tile {
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.job-tile:hover {
  border-color: #c52422;
}

.job-details-page {
  font-family: 'Arial', sans-serif;
  padding: 20px;
}

.job-details h1 {
  font-family: 'Copperplate Gothic','Copperplate', 'Palatino Linotype', serif; /* This is for the job title */
  font-size: 36px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.job-details h3 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
}

.job-details ul {
  margin: 10px 0;
  padding-left: 20px;
}

.job-details ul li {
  font-size: 16px;
  margin-bottom: 10px;
}

/* Align responsibilities, skills, and qualifications side by side */
.job-details .responsibilities, .job-details .skills, .job-details .qualifications {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: max-content;
  padding: 1%;
}

.job-details .responsibilities ul,
.job-details .skills ul,
.job-details .qualifications ul {
  padding: 0;
  margin: 0;
  width: 30%;
}

.job-details .responsibilities ul li,
.job-details .skills ul li,
.job-details .qualifications ul li {
  font-size: 14px;
  font-weight: 400;
}

/* Styling for the description */
.job-details p {
  font-size: 16px;
  margin-top: 20px;
  line-height: 1.6;
  white-space: pre-line; /* Ensures proper spacing for line breaks */
}

/* Applied message styling */
.applied-message {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px solid #ccc;
  margin-top: 20px;
  font-size: 16px;
}

.applied-message a {
  color: #c52422;
  text-decoration: none;
  font-weight: bold;
}

/* Apply form section */
.apply-section {
  margin-top: 20px;
}

.apply-button{
  margin-top: 2%;
  margin-left: 40%;
  padding: 10px 20px;
  background-color: #c52422;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.attrs{
  display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;
}

.submit-btn {
  margin-top: 2%;
  margin-left: 40%;
  padding: 10px 20px;
  background-color: #c52422;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.job-details-page textarea{
  width: 90%;
}

.applied-message {
  margin-top: 20px;
  padding: 10px;
  background-color: #e8f4e5;
  border: 1px solid #c3e6cb;
  color: #155724;
}

.applications-page {
  padding: 20px;
}

.applications-page h2 {
  margin-bottom: 20px;
}

.applications-page table {
  width: 100%;
  border-collapse: collapse;
}

.applications-page table th, .applications-page table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.applications-page table th {
  background-color: #f4f4f4;
}

.applications-page table td a {
  color: #007BFF;
  text-decoration: none;
}

.applications-page table td a:hover {
  text-decoration: underline;
}

.applications-page table td button {
  margin-right: 10px;
  padding: 5px 10px;
  border: none;
  background-color: #c52422;
  color: white;
  cursor: pointer;
}

.applications-page table td button:hover {
  background-color: #a21e1e;
}



