.view-applications-page {
    padding: 20px;
  }
  

body.no-scroll{
  overflow: hidden;
}

.filters-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  width: 70%;
}

/* Add status colors */
.status-applied {
  color: black;
}

.status-approved {
  color: green;
}

.status-rejected {
  color: red;
}

.status-on-hold {
  color: yellow;
}

/* Other existing styles */


.filters-section select {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-right: 10px;
  cursor: pointer;
}

.date-range-filter {
  display: flex;
  align-items: center;
}

.date-range-filter .react-datepicker-wrapper {
  position: relative;
}

.controls-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.status-filter,
#limit {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-bar input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.search-bar input:focus {
  outline: none;
  border-color: #007bff;
}

.applications-limit {
  display: flex;
  align-items: center;
}

.applications-limit label {
  margin-right: 10px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  padding: 8px 16px;
  margin: 0 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.pagination button[disabled] {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination span {
  font-size: 16px;
}


.date-range-filter input {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #ddd;
  width: 150px;
  margin-right: 10px;
}

.date-range-filter .calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.apply-filters-btn {
  padding: 8px 15px;
  font-size: 14px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}

.apply-filters-btn:hover {
  background-color: #45a049;
}
  .applications-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .applications-table th, .applications-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .applications-table tr {
    cursor: pointer;
  }
  
  .applications-table tr:hover {
    background-color: #f1f1f1;
  }
  
  /* Sliding window from the right */
  .sliding-window {
    position: fixed;
    top: 0;
    right: -100%;
    width: 30%;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    transition: right 0.3s ease;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .sliding-window.open {
    right: 0;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .cover-letter-box {
    width: 100%;
    height: 150px;
    margin-top: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    resize: none;
  }
  
  .actions {
    margin-top: 20px;
  }
  
  .actions button {
    padding: 10px 15px;
    margin-right: 10px;
    cursor: pointer;
  }
  
  .approve-btn {
    background-color: #4CAF50;
    color: white;
    border: none;
  }
  
  .reject-btn {
    background-color: #f44336;
    color: white;
    border: none;
  }
  
  .hold-btn {
    background-color: #ff9800;
    color: white;
    border: none;
  }

/* Sliding Window */
.sliding-window {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px;
    height: 100%;
    background-color: #fff;
    box-shadow: -2px 0px 10px rgba(0, 0, 0, 0.2);
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    overflow-y: auto;
  }
  
  .sliding-window.open {
    transform: translateX(0);
  }
  
  .close-btn {
    background-color: #c52422;
    color: white;
    padding: 5px 10px;
    cursor: pointer;
    border: none;
    float: right;
  }
  
  .actions button {
    margin: 10px;
  }
  
  .custom-message-popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  
  .custom-message-popup textarea {
    width: 100%;
    margin: 10px 0;
  }
  
  .custom-message-popup button {
    margin-right: 10px;
  }

  tr:hover{
    cursor: pointer;
  }

.coverLetter{
    padding: 1%;
    font-family: sans-serif;
    border: 0.1rem solid black;
    border-radius: 0.2rem;
}